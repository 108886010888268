@font-face {
  font-family: "VWHeadBold";
  src: url("./assets/fonts/VWHeadBold.otf");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "VWHeadRegular";
  src: url("./assets/fonts/VWHeadRegular.otf");
  font-weight: 200;
  font-style: normal;
}
@font-face {
    font-family: "VWTextBold";
    src: url("./assets/fonts/VWTextBold.otf");
    font-weight: 800;
    font-style: normal;
  }
  @font-face {
    font-family: "VWTextRegular";
    src: url("./assets/fonts/VWTextRegular.otf");
    font-weight: 200;
    font-style: normal;
  }
