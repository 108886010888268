/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@import './fonts.scss';

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// Text
.vwHeadBold    { font-family: "VWHeadBold" }
.vwHeadRegular { font-family: "VWHeadRegular" }
.vwTextBold    { font-family: "VWTextBold" }
.vwTextRegular { font-family: "VWTextRegular" }
.white { color: #fff }
.yellow { color: #ffed00 }
.gray { color: #1C3546 }
.lightgray { color: #AFAFAF }
.text-center { text-align: center }
.text-left { text-align: left }
.text-right { text-align: right }
.underline_hover { cursor: pointer }
.underline_hover:hover { text-decoration: underline; }
.bold { font-weight: bold; }
.font10 { font-size: 10px }
.font12 { font-size: 12px }
.font14 { font-size: 14px }
.font15 { font-size: 15px }
.font16 { font-size: 16px }
.font18 { font-size: 18px }
.font20 { font-size: 20px }
.font22 { font-size: 22px }
.font24 { font-size: 24px }
.font26 { font-size: 26px }
.font28 { font-size: 28px }
.font32 { font-size: 32px }
.font36 { font-size: 36px }
.font40 { font-size: 40px }
.font45 { font-size: 45px }
.font52 { font-size: 52px }

// Margins
.mt0 { margin-top: 0 }
.mt3 { margin-top: 3px }
.mt5 { margin-top: 5px }
.mt10 { margin-top: 10px }
.mt15 { margin-top: 10px }
.mt20 { margin-top: 20px }
.mt25 { margin-top: 25px }
.mt30 { margin-top: 30px }
.mt35 { margin-top: 35px }
.mt40 { margin-top: 40px }
.mt45 { margin-top: 45px }
.mt50 { margin-top: 50px }
.mt55 { margin-top: 55px }
.mt60 { margin-top: 60px }
.mt65 { margin-top: 65px }
.mb0 { margin-bottom: 0 }
.mb5 { margin-bottom: 5px }
.mb10 { margin-bottom: 10px }
.mb15 { margin-bottom: 10px }
.mb20 { margin-bottom: 20px }
.mb25 { margin-bottom: 25px }
.mb30 { margin-bottom: 30px }
.mb35 { margin-bottom: 35px }
.mb40 { margin-bottom: 40px }
.mb45 { margin-bottom: 45px }
.mb50 { margin-bottom: 50px }
.mb55 { margin-bottom: 55px }
.mb60 { margin-bottom: 60px }
.mb100 { margin-bottom: 100px }

// Paddings
.padHor30 {
    padding:0 30px;
}

// Positioning
.floatLeft { float: left }
.floatRight { float: right }

// Containers 
.div10 { display:inline-block; width:10% }
.div20 { display:inline-block; width:20% }
.div30 { display:inline-block; width:30% }
.div40 { display:inline-block; width:40% }
.div50 { display:inline-block; width:50% }
.div60 { display:inline-block; width:60% }
.div70 { display:inline-block; width:70% }
.div80 { display:inline-block; width:80% }
.div90 { display:inline-block; width:90% }


// Buttons
button:focus {outline:0 !important}
button:hover {transform: scale(0.97);}
.btnVazadoBranco {
    background: rgba(0,0,0,0);
    width:100%;
    color: #fff;
    border: 1px solid #fff;
    padding: 14px 0 17px 0;
    font-family: "VWTextRegular";
    font-size:18px;
    border-radius:50px;
}
.btnVazadoCinza {
    background: rgba(0,0,0,0);
    width:100%;
    color: #1C3546;
    border: 1px solid #1C3546;
    padding: 14px 0 17px 0;
    font-family: "VWTextRegular";
    font-size:18px;
    border-radius:50px;
}
.btnCheioCinza {
    background: #001E50;
    width:100%;
    color: #fff;
    padding: 14px 0 17px 0;
    font-family: "VWTextRegular";
    font-size:18px;
    border-radius:50px;
}
.btnCheioAmarelo {
    background: #F8AC00;
    color: #1C3546;
    padding: 7px 20px 11px 20px;
    font-family: "VWTextRegular";
    font-size:16px;
    border-radius:50px;
}
.btnWhatsapp {
    background: #26BB3D;
    width:100%;
    color: #fff;
    padding: 14px 0 17px 0;
    font-family: "VWTextRegular";
    font-size:18px;
    border-radius:50px;
}


// Inputs
.inputTransparenteBranco {
    width:100%;
    height:36px;
    background: rgba(0,0,0,0) !important;
    border:none;
    border-bottom: 1px solid #fff;
    color:#fff;
    font-size:18px;
}
.inputTransparenteCinza {
    width:100%;
    height:36px;
    background: rgba(0,0,0,0) !important;
    border:none;
    border-bottom: 1px solid #1C3546;
    color:#1C3546;
    font-size:18px;
}
.inputSearch {
    width:100%;
    height:40px;
    background: rgba(255,255,255,0.8) !important;
    border:none;
    border-radius: 40px;
    color:#1C3546;
    font-size:16px;
    box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.1);
    padding: 0 24px
}
input:focus {outline:0 !important}
select:focus {outline:0 !important}
textarea:focus {outline:0 !important}
select { border-radius: 0 !important; }


// BGs
.bgAmarelo { background: #FFED00 }
.maskAmarela { background: rgba(255, 255, 255, 0.6) }
.maskBranca { background: rgba(255,255,255, 0.3) }
.maskPreta { background: rgba(0,0,0, 0.3) }


// Modais
.mask { 
    position: fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    z-index:10;
    display:none;
}
.modal {
    text-align: center;
    position: fixed;
    z-index: 99;
    top: 10vh;
    width:90vw;
    left:5vw;
    padding:40px 30px;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    display: none;
}
.modal img {
    width:100px;
    margin-bottom: 15px;
}
.modalClaro {
    background: #fff;
    color: #1C3546;
}
.modalClaro button {
    background: #001E50;
    width:100px;
    color: #fff;
    padding: 14px 0 17px 0;
    font-family: "VWTextRegular";
    font-size:18px;
    border-radius:50px;
    margin-top:40px
}
.modalEscuro {
    color: #fff;
    background: #1C3546;
}
.modalEscuro button {
    background: #F8AC00;
    width:100px;
    color: #1C3546;
    padding: 14px 0 17px 0;
    font-family: "VWTextRegular";
    font-size:18px;
    border-radius:50px;
    margin-top:40px
}


// Components
.menu {
    position: relative;
    left: 6.6vw;
    top: 5.066vw;
    width: 14.222vw;
    height: 14.222vw;
    border-radius: 14.222vw;
    background: url("assets/imgs/home/menu.svg") top left no-repeat;
    background-size: cover;
  }


